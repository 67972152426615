import { useRef } from "react";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import Image from "../image/Image";
import { handleModalTriggerEvent } from "./utils/handleModalTriggerEvent";
import styles from "./LooksImageButton.scss";
import { LOOKS_POSITION } from "../../analytics/looks/looksModal";
import { LOOKS_OPEN_MODAL_EVENT } from "./constants";

const LooksImageButton = () => {
  const { getTranslation, isMobile, looksData } = useRuntimeConstants();

  const buttonRef = useRef(null);

  const handleClick = () => {
    handleModalTriggerEvent({
      focusOnCloseElement: buttonRef.current,
      position: LOOKS_POSITION.BOTTOM,
      eventKey: LOOKS_OPEN_MODAL_EVENT,
      isMobile,
    });
  };

  return (
    <section className={styles.section} data-testid="looksImageButtonSection">
      <button
        onClick={handleClick}
        data-testid="looksButton"
        className={styles.button}
        ref={buttonRef}
      >
        <div className={styles.container}>
          <h2 className={styles.title}>
            {getTranslation("pdp_associated_buy_the_look_title")}
          </h2>
          <p className={styles.subtitle}>
            {getTranslation("pdp_buy_the_look_subtitle")}
          </p>

          <Image
            url={`//${looksData.lookImageUrl}`}
            alternateText={getTranslation(
              "pdp_carousel_buy_the_look_image_gallery_alttext"
            )}
            type={"collage"}
            width={640}
            additionalClasses={styles.image}
            loadingType={"lazy"}
          />
        </div>
      </button>
    </section>
  );
};

export default LooksImageButton;
