import Polyglot from "node-polyglot";
import getWindow from "./utils/window";
import { getBreadcrumbsForAnalytics } from "./utils/analyticsBreadcrumbs";
import { getParamFromReferrer } from "./utils/getParamFromReferrer";
import buildUrlParamsObject from "./buildUrlParamsObject";
import getConfig from "./config";

let runtimeConstants;

export default function getRuntimeConstants() {
  if (!getWindow()?.asos) {
    return {};
  }

  if (!runtimeConstants) {
    const {
      breadcrumbsData,
      defaultAnalyticsBreadcrumbs,
      looks,
      translations: phrases,
      ...rest
    } = getConfig();

    const translations = new Polyglot({
      phrases,
      locale: getWindow()
        .asos.webContext.getWebContext()
        .browseLanguage.split("-")[0],
    });

    const hostAndPath = getWindow()
      .location.href.split("?")[0]
      .replace(/\/$/, "");
    const isMixAndMatch = getWindow().location.pathname.includes("/grp/");
    const urlParams = buildUrlParamsObject();

    const canonicalUrl = getWindow().document.querySelector(
      "link[rel=canonical]"
    ).href;

    const webContext = getWindow().asos.webContext.getWebContext();

    const referrerData = {
      searchQuery: getParamFromReferrer({ param: "q" }),
      cid: getParamFromReferrer({ param: "cid" }),
    };

    const analyticsBreadcrumbs = getBreadcrumbsForAnalytics({
      breadcrumbsData,
      defaultAnalyticsBreadcrumbs,
      referrerData,
    });

    const looksData = looks
      ? {
          lookImageUrl: looks[0].lookImageUrl,
          looksProductsFromServer: looks[0].products,
        }
      : undefined;

    const oosVariantsAreSelectable = webContext.storeId !== "DE";

    runtimeConstants = {
      ...rest,
      hostAndPath,
      canonicalUrl,
      urlParams,
      isMixAndMatch,
      translations,
      webContext,
      analyticsBreadcrumbs,
      breadcrumbsData,
      referrerData,
      looksData,
      oosVariantsAreSelectable,
    };
  }
  return runtimeConstants;
}
