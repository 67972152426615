import { getPrimaryImage } from "../../../utils/product";

const getLookHeroProduct = (looksProducts) => {
  return looksProducts.find((product) => product.isHero) || looksProducts[0];
};

export const getLookHeroImageUrl = ({ product, looksProducts }) =>
  getLookHeroProduct(looksProducts)?.lookProductImage
    ? `//${getLookHeroProduct(looksProducts).lookProductImage}`
    : getPrimaryImage(product.images).url;
