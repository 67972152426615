import PropTypes from "prop-types";
import { GridPrice } from "../priceComponents";
import styles from "./PriceThenTitle.scss";

export default function PriceThenTitle({ priceData, title }) {
  return (
    <>
      <GridPrice priceData={priceData} />
      <h3 className={styles.title} title={title}>
        {title}
      </h3>
    </>
  );
}

PriceThenTitle.propTypes = {
  priceData: PropTypes.shape({
    current: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }).isRequired,
    previous: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
    rrp: PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
    isMarkedDown: PropTypes.bool,
    isOutletPrice: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
};
