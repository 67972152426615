import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LooksCarousel from "./LooksCarousel";
import Image from "../image/Image";
import LooksProduct from "./LooksProduct";
import styles from "./LooksSection.scss";
import { useLooksProducts } from "../../context/looks/LooksProductsContext";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useSelectedLooksProduct } from "../../context/looks/SelectedLooksProductContext";
import { VariantContextProvider } from "../../context/variant/VariantContext";
import { ProductContextProvider } from "../../context/product/ProductContext";
import { LOOKS_SECTION_ID } from "./constants";
import { fireLooksProductViewTracking } from "../../analytics/looks/looksProduct";
import { getLookHeroImageUrl } from "./utils/heroProductUtils";

const LooksDesktopSection = ({ product }) => {
  const { getTranslation } = useRuntimeConstants();
  const { looksProducts } = useLooksProducts();
  const { selectedLooksProduct } = useSelectedLooksProduct();
  const [selected, setSelected] = useState(selectedLooksProduct.id);

  const { length: looksProductCount } = looksProducts.filter(
    (product) => product.isHero === false
  );

  useEffect(() => {
    if (selected !== selectedLooksProduct?.id) {
      fireLooksProductViewTracking({
        looksProducts,
        selectedLooksProduct,
        isMobile: false,
      });
      setSelected(selectedLooksProduct.id);
    }
  }, [looksProducts, selected, selectedLooksProduct]);

  return (
    <section
      data-testid="looksDesktopSection"
      className={styles.container}
      id={LOOKS_SECTION_ID}
    >
      <div className={styles.subContainer}>
        <div className={styles.header}>
          <div>
            <h2 className={styles.title} id="buyTheLookTitle" tabIndex={-1}>
              {getTranslation("pdp_associated_buy_the_look_title")}
            </h2>
            <p className={styles.subtitle}>
              {getTranslation("pdp_buy_the_look_subtitle")}
            </p>
          </div>
          <p data-testid="looksItemCount" className={styles.itemCount}>
            {getTranslation("buy_the_look_items_count", {
              smart_count: looksProductCount,
              count: looksProductCount,
            })}
          </p>
        </div>
        <div className={styles.product}>
          <Image
            url={getLookHeroImageUrl({ product, looksProducts })}
            alternateText={product.name}
            type={"hero"}
            width={370}
            additionalClasses={styles.heroImage}
            loadingType={"lazy"}
          />
          <div className={styles.productAside}>
            <LooksCarousel includeHeroProduct={false} />
            <ProductContextProvider
              products={looksProducts}
              productId={selectedLooksProduct.id}
              key={selectedLooksProduct.id}
            >
              <VariantContextProvider>
                <LooksProduct />
              </VariantContextProvider>
            </ProductContextProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

LooksDesktopSection.propTypes = {
  product: PropTypes.object.isRequired,
};

export default LooksDesktopSection;
