import { createContext, useContext, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import getStockPrice from "../../api/stockPriceApi";
import { useRuntimeConstants } from "../runtimeConstants/RuntimeConstantsContext";
import { variantMapper } from "../../../mappers/variant";
import { hasAssociatedLooks } from "../../components/looks/utils/hasAssociatedLooks";
import setLooksTracking from "../../analytics/pageLoad/looks";

export const LooksProductsContext = createContext({});

const shouldMapLooksProducts = ({ isMixAndMatch, looksData }) =>
  looksData?.looksProductsFromServer.length > 0 && !isMixAndMatch;

export const LooksProductsProvider = ({ children }) => {
  const { product, looksData, isMixAndMatch } = useRuntimeConstants();

  const [looksProducts, setLooksProducts] = useState(null);
  const [shouldShowLooksSection, setShouldShowLooksSection] = useState(null);

  useEffect(() => {
    (async function mapLooksProducts() {
      if (
        shouldMapLooksProducts({
          isMixAndMatch,
          looksData,
        })
      ) {
        const stockPriceProducts = await getStockPrice(product);
        const mappedLooksProducts = mapLooksProductsWithStockPrice({
          stockPriceProducts,
          looksData,
        });
        setLooksProducts(mappedLooksProducts);
        const showLooksCTA = hasAssociatedLooks({
          looksProducts: mappedLooksProducts,
        });
        setShouldShowLooksSection(showLooksCTA);
        setLooksTracking(showLooksCTA);
        return;
      }
      setShouldShowLooksSection(false);
      if (!isMixAndMatch) {
        setLooksTracking(false);
      }
    })();
  }, [product, looksData, isMixAndMatch]);

  return (
    <LooksProductsContext.Provider
      value={{ looksProducts, shouldShowLooksSection }}
    >
      {children}
    </LooksProductsContext.Provider>
  );
};

const mapLooksProductsWithStockPrice = ({ stockPriceProducts, looksData }) => {
  const looksProducts = looksData.looksProductsFromServer.map(
    (looksProduct) => {
      let mappedLooksProduct;
      const stockPriceForProduct = stockPriceProducts.find(
        (stockPriceProduct) => stockPriceProduct.productId === looksProduct.id
      );

      if (stockPriceForProduct) {
        mappedLooksProduct = {
          ...looksProduct,
          hasMultipleColoursInStock:
            stockPriceForProduct.hasMultipleColoursInStock,
          hasMultiplePricesInStock:
            stockPriceForProduct.hasMultiplePricesInStock,
          isInStock: stockPriceForProduct.isInStock,
          price: stockPriceForProduct.productPrice,
          restockingSoonDetails: stockPriceForProduct.restockingSoonDetails || {
            shouldShowRestockingSoon: false,
          },
        };

        mappedLooksProduct.variants = mappedLooksProduct.variants?.map(
          (looksVariant) => {
            const stockPriceForVariant = stockPriceForProduct.variants?.find(
              (stockPriceVariant) =>
                stockPriceVariant.id === looksVariant.variantId
            );

            const stockPriceMapping = variantMapper(
              stockPriceForVariant,
              !!stockPriceForProduct.productPrice &&
                !!stockPriceForVariant.price
            );

            return {
              ...stockPriceMapping,
              ...looksVariant,
            };
          }
        );
      }

      return mappedLooksProduct;
    }
  );

  return filterOutProductsWithoutPrice(looksProducts.filter(Boolean));
};

const filterOutProductsWithoutPrice = (looksProducts) =>
  looksProducts.filter(
    (looksProduct) => looksProduct.isHero || looksProduct.price !== undefined
  );

export const useLooksProducts = () => useContext(LooksProductsContext);

LooksProductsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
